body {
  margin: 0;
  font-family: 'Roboto', 'Arial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table img {
  border: 1px solid rgba(224, 224, 224, 1);
  height: 13px;
  width: 13px;
  margin: 0 !important;
  max-width: none;
  max-height: none;
}

.MuiFilledInput-root,
.MuiFilledInput-root {
  background-color: #fafafa !important;
}

.MuiSwitch-colorPrimary.Mui-checked,
.MuiRadio-colorPrimary.Mui-checked,
.focus {
  color: #0094a0 !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #0094a0 !important;
}

div[class^='RaFileInput'] {
  font-size: 12px;
}

img.responsive {
  max-width: 100%;
}

.ra-rich-text-input .ql-editor {
  min-height: 100px;
}

div[data-testid='dropzone'] {
  background: rgba(0, 148, 160, 1);
  color: #fff;
  border-radius: 5px;
}

.panel-group {
  padding: 5px;
  background-color: #fafafa;
  border-color: rgba(224, 224, 224, 1);
  border-style: solid;
  border-width: 1px;
  border-top-width: 4px;
  margin-bottom: 20px;
}

.panel-group strong,
.panel-group .title {
  font-size: 16px;
  font-weight: bold;
  font-style: italic;
  margin-top: 5px;
  margin-bottom: 5px;
  /*color: #0094a0;*/
}

.panel-group .MuiFormControl-root {
  margin-top: 0;
  margin-bottom: 0;
}

.panel-group ul {
  margin-top: 0;
}

img {
  max-width: 100%;
  height: 'auto';
}

.MuiTab-root {
  text-transform: none !important;
}

#root > div,
.MuiButton-containedPrimary,
.MuiFab-primary,
.MuiAppBar-root {
  background: #000 !important;
}

div.layout,
#main-content {
}

.MuiButton-containedPrimary,
.MuiAppBar-colorSecondary {
  color: #fff !important;
}

.error {
  background-color: #c00;
  padding: 2px;
  text-align: center;
  border-radius: 5px;
}

.warning {
  background-color: #eed202;
  padding: 2px;
  text-align: center;
  border-radius: 5px;
}

.subAppBar {
  background: rgba(0, 148, 160, 0.8);
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.subAppBar.warning {
  background: rgba(238, 210, 2, 0.2);
  color: #000;
}


.subAppBar.error {
  background: rgba(204, 0, 0, 0.9);
  color: #fff;
}
.subAppBar p {
  text-align: center;
  margin: 5px;
  font-size: 12px;
  justify-content: center;
}

.subAppBar a {
  display: inline-block;
  background: rgba(0, 148, 160, 1);
  color: #fff;
  text-decoration: none;
  padding: 8px;
}

.subAppBar a.inline {
  background-color: transparent;
  color: inherit;
  padding-top:0;
  padding-bottom:0;
  padding-left:2px;
  padding-right:2px;
  text-decoration: underline;
}

.subAppBar a.focus {
  background: #fff;
}

.subAppBar a:hover {
  text-decoration: underline;
}

.subAppBar a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.subAppBar a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.underline {
  text-decoration: underline;
}

.disabled {
  background-color: red;
}
